export const statLabel = (key) => {
  const labels = {
    averageSessionDuration: "average session duration",
    averageMessagesPerSession: "average messages per session",
    retentionRate: "retention rate",
    aiSuccessPerformanceRate: "ai success performance rate",
    aiFailurePerformanceRate: "ai failure performance rate",
    escalationRate: "escalation rate",
    averageBotResponseTime: "average bot response time",
  };

  return labels[key];
};

export function largestDivisorOrIncrement(num) {
  // Helper function to find the largest divisor of a number
  function largestDivisor(n) {
    for (let i = n - 1; i >= 1; i--) {
      if (n % i === 0) {
        return i;
      }
    }
    return 1; // If no divisor found, return 1 (prime numbers)
  }

  // Check if the number is prime
  const largestDiv = largestDivisor(num);
  if (largestDiv === 1) {
    // Number is prime, so increment and find largest divisor of (num + 1)
    return largestDivisor(num + 1);
  }

  // Return the largest divisor if the number is not prime
  return largestDiv;
}
